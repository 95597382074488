<template>
  <b-modal
    v-model="value"
    title="Add/Edit Speaker"
    ref="speaker-modal"
    id="speaker-modal"
  >
    <b-form @submit.prevent="save">
      <b-form-group label-size="lg" label="Avatar">
        <ImageUpload
          :url="speaker.avatar"
          ref="image"
          :title="$t('avatar')"
          @updateImage="updateImage"
        />
      </b-form-group>
      <b-form-group label="Name">
        <b-form-input
          name="Name"
          v-validate="'required|min:3|max:50'"
          v-model="speaker.name"
        ></b-form-input>
        <div v-if="submitted && errors.has('name')" class="alert-danger">
          {{ errors.first("name") }}
        </div>
      </b-form-group>
      <b-form-group label="Description">
        <b-form-textarea
          name="Description"
          v-model="speaker.description"
        ></b-form-textarea>
      </b-form-group>
      <b-form-group label="URL">
        <b-form-input
          name="Url"
          v-model="speaker.url"
        ></b-form-input>
      </b-form-group>
    </b-form>
    <template #modal-footer>
      <div
        v-if="message"
        class="alert"
        :class="successful ? 'alert-success' : 'alert-danger'"
      >
        {{ message }}
      </div>
      <b-button type="submit" @click="save()">Save</b-button>
      <b-button @click="value = false">Close</b-button>
    </template>
  </b-modal>
</template>
<script>
import ImageUpload from "@/components/admin/ImageUpload.vue";

export default {
  props: [
    "value",
    "speakers",
    "speaker",
    "speakerIndex",
    "language",
  ],
  components: {
    ImageUpload,
  },
  data() {
    return {
      message: "",
      submitted: false,
      successful: false,
    };
  },
  watch: {
    value() {
      this.$emit("input", this.value);
    },
  },
  methods: {
    updateImage(newUrl) {
      this.speaker.avatar = newUrl;
    },
    async save() {
      this.message = "";
      this.submitted = true;
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          return;
        }
        if (this.speakerIndex !== null) {
          this.speakers[this.language][this.speakerIndex] = this.speaker;
          this.$emit("input", false);
        } else {
          this.speakers[this.language].push(this.speaker);
          this.$emit("input", false);
        }
      });
    },
  },
};
</script>
