<template>
  <div>
    <h1>{{ title }}</h1>
    <b-button @click="add()" class="mb-3">{{ addText }}</b-button>
    <vuetable ref="cardtable" :api-mode="false" :fields="fields" :data="object">
      <template slot="actions" scope="props">
        <font-awesome-icon
          icon="edit"
          data-toggle="tooltip"
          data-placement="top"
          title="Edit slide"
          @click="edit(props.rowIndex)"
          class="action-icon"
          ref="btnEdit"
        ></font-awesome-icon>
        <font-awesome-icon
          icon="trash"
          data-toggle="tooltip"
          data-placement="top"
          title="Remove slide"
          class="ml-2 action-icon"
          @click="showDeleteModal(props.rowIndex)"
        ></font-awesome-icon>
      </template>
    </vuetable>
    <TableDetail
      v-model="showModal"
      :object="object"
      :type="type"
      :selected="selected"
      :index="selectedIndex"
      :title="detailTitle"
    />
  </div>
</template>
<script>
import TableDetail from "@/components/admin/home/TableDetail.vue";
import Vuetable from "vuetable-2";
import { CarouselSlide } from "@/models/slide.model";
import { CardItem } from "@/models/card-item.model";

export default {
  props: [
    "object",
    "type",
    "title",
    "addText",
    "detailTitle",
    "fields"
  ],
  components: {
    Vuetable,
    TableDetail,
  },
  data() {
    return {
      selected: "",
      selectedIndex: null,
      showModal: false,
      blockIndex: null,
      confirmBox: ''
    };
  },
  methods: {
    edit(index) {
      this.selectedIndex = index
      this.selected = this.object[index];
      this.toggleModal();
    },
    add() {
      this.selectedIndex = null
      if (this.type == "items") {
        this.selected = new CardItem("card", true, {}, {}, {}, "", "", "", true);
      } else if (this.type == "slides") {
        this.selected = new CarouselSlide({}, "", {}, {}, {})
      }
      this.toggleModal();
    },
    showDeleteModal(index) {
      this.$bvModal.msgBoxConfirm(this.$i18n.t("deletePrompt"))
        .then(value => {
          if (value) {
            this.object.splice(index, 1) 
          }
        })
        .catch(err => {
          // An error occurred
        })
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
  },
};
</script>
<style lang="scss">
$color-primary: var(--primary);
$color-secondary: var(--secondary);
$text-color-header: var(--theme-color-text_color_header);
$text-color-title: var(--theme-color-text_color_title);
$text-color-body: var(--theme-color-text_color_body);
$font-family: var(--theme-font-family);
$background-image: var(--theme-background-image);

.action-icon:hover {
  color: $color-secondary;
  cursor: pointer;
}
</style>