<template>
<div>
  <h2 class="mt-3">{{$t('chatQ&a')}}</h2>
  <b-tabs class="mt-3">
    <b-tab v-for="language in languages" :key="language">
      <template #title>
        <country-flag :country="language" size="normal" />
      </template>
      <b-form-checkbox
        v-model="chat.active"
        name="active"
        class="content-switch mt-3 mb-3"
        switch
        ><b>{{ $t("active") }}</b></b-form-checkbox
      >
      <b-form-group class="mb-2 mr-sm-2 mb-sm-0" label="Platform">
        <b-form-select
          v-model="chat.type[language]"
          :options="chatPlatforms"
        >
        </b-form-select>
      </b-form-group>

      <!-- ID -->
      <b-form-group class="mt-3 mb-2 mr-sm-2 mb-sm-0" label="Chat code">
        <b-input-group :prepend="URLPrefix(chat.type[language])">
          <b-form-input v-model="chat.code[language]"></b-form-input>
          <!-- <a>Where can I find this?</a> -->
        </b-input-group>
      </b-form-group>
      <b-form-group v-if="chat.type[language] == 'embedded-chat'" class="mt-3 mb-2 mr-sm-2 mb-sm-0" label="Chat room code">
        <b-input-group>
          <b-form-input v-model="chat.roomCode[language]"></b-form-input>
          <!-- <a>Where can I find this?</a> -->
        </b-input-group>
      </b-form-group>
    </b-tab>
  </b-tabs>
</div>
</template>
<script>
export default {
  props: ["chat"],
  data() {
    return {
      chatPlatforms: [
        { value: "slido", text: "Slido" },
        { value: "vimeo", text: "VimeoChat" },
        { value: "embedded-chat", text: "EmbeddedChat" }
      ],
    };
  },
  methods: {
    URLPrefix(type) {
      let url;
      switch (type) {
        case "vimeo":
          url = "https://vimeo.com/event/";
          break;
        case "slido":
          url = "https://app.sli.do/event/";
          break;
        case "embedded-chat":
          url = null;
          break;
        default:
          url = "No livestream platform specified";
      }
      return url;
    },
  },
  computed: {
    languages() {
      return this.$store.state.Languages.all;
    },
  },
};
</script>
