<template>
  <div>
      <b-form-group label="Type">
        <b-select
          name="type"
          v-validate="'required'"
          :options="itemTypes"
          v-model="item.type"
        ></b-select>
        <div v-if="submitted && errors.has('type')" class="alert-danger">
          {{ errors.first("type") }}
        </div>
      </b-form-group>
      <b-tabs content-class="mt-3" nav-class="no-padding">
        <b-tab v-for="(language, index) in languages" :key="index">
          <template #title>
            <country-flag :country="language" size="normal" />
          </template>
          <b-form-group label="Title">
            <b-form-input
              name="title"
              v-validate="'required|min:3|max:50'"
              v-model="item.title[language]"
            ></b-form-input>
            <div v-if="submitted && errors.has('title')" class="alert-danger">
              {{ errors.first("title") }}
            </div>
          </b-form-group>
          <b-form-group label="Category">
            <b-form-input
              name="category"
              v-validate="'required|min:3|max:50'"
              v-model="item.category[language]"
            ></b-form-input>
          </b-form-group>
          <div v-if="submitted && errors.has('category')" class="alert-danger">
            {{ errors.first("category") }}
          </div>
          <b-form-group label="Description">
            <b-form-textarea
              name="description"
              v-model="item.description[language]"
            ></b-form-textarea>
          </b-form-group>
        </b-tab>
      </b-tabs>
      <b-form-group label-size="lg" label="Background image">
        <ImageUpload :url="item.image" ref="image" :title="$t('item')" @updateImage="updateImage"/>
      </b-form-group>
      <b-form-group label="Url">
        <b-form-input v-model="item.url" />
      </b-form-group>
      <b-form-group label="Start date">
        <flat-pickr
          v-model="item.date"
          :config="flatPickerConfig"
        ></flat-pickr>
      </b-form-group>
  </div>
</template>
<script>
import axios from "@/plugins/axios.plugin";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import ImageUpload from "@/components/admin/ImageUpload.vue"
export default {
  props: ["item", "index", "submitted"],
  components: {
    flatPickr,
    ImageUpload
  },
  data() {
    return {
      itemTypes: ["card"],
      flatPickerConfig: {
        enableTime: true,
        dateFormat: "Y-m-d H:i",
      },
    };
  },
  computed: {
    languages() {
      return this.$store.state.Languages.all;
    },
  },
  methods: {
    updateImage(newUrl) {
      this.item.image = newUrl
    }
  },
};
</script>
