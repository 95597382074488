<template>
  <div>
    <h2 class="mt-3">{{ $t("speakers") }}</h2>
    <b-tabs class="mt-3">
      <b-tab v-for="language in languages" :key="language">
        <template #title>
          <country-flag :country="language" size="normal" />
        </template>
        <b-form-checkbox
          v-model="speakers.active"
          name="active"
          class="content-switch mt-3 mb-3"
          switch
          ><b>{{ $t("active") }}</b></b-form-checkbox
        >
        <b-button class="mb-2 save-button" @click="add(language)">
          {{ $t("add") }}
        </b-button>
        <vuetable
          ref="speakerstable"
          :api-mode="false"
          :fields="['name', 'description', 'url', 'actions']"
          :data="speakers[language]"
        >
          <template slot="actions" scope="props">
            <font-awesome-icon
              icon="edit"
              class="action-icon"
              data-toggle="tooltip"
              data-placement="top"
              title="Edit item"
              @click="edit(language, props.rowIndex)"
              ref="btnEdit"
            ></font-awesome-icon>
            <font-awesome-icon
              icon="trash"
              class="ml-2 action-icon"
              data-toggle="tooltip"
              data-placement="top"
              title="Remove item"
              @click="deleteSpeaker(props.rowIndex)"
            ></font-awesome-icon>
          </template>
        </vuetable>
      </b-tab>
    </b-tabs>
    <SpeakerDetailModal
      :speakers="speakers"
      v-model="showModal"
      :speakerIndex="index"
      :language="selectedLanguage"
      :speaker="selected"
    />
  </div>
</template>
<script>
import Vuetable from "vuetable-2";
import SpeakerDetailModal from "@/components/admin/livestreams/SpeakerDetailModal.vue";
import { Speaker } from "@/models/speaker.model"

export default {
  props: ["livestreams", "speakers", "livestreamIndex"],
  components: {
    SpeakerDetailModal,
    Vuetable,
  },
  data() {
    return {
      index: null,
      selected: null,
      selectedLanguage: null,
      showModal: false,
    };
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
    },
    edit(language, index) {
      this.index = index;
      this.selected = this.speakers[language][this.index];
      this.selectedLanguage = language;
      this.toggleModal();
    },
    add(language) {
      this.index = null;
      this.selected = new Speaker("", "", "", "");
      this.selectedLanguage = language;
      this.toggleModal();
    },
    async deleteSpeaker(index) {
      this.isLoading = true;
      const isConfirmed = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to remove the speaker?`,
        {
          title: "Confirm delete",
          okVariant: "danger",
          centered: true,
        }
      );
      if (isConfirmed) {
        console.log(this.livestreams[this.livestreamIndex].speakers[this.locale])
        this.livestreams[this.livestreamIndex].speakers[this.locale].splice(index, 1);
        await this.$store.dispatch("Livestreams/update", this.livestreams);
      }
      this.isLoading = false;
    },
  },
  computed: {
    languages() {
      return this.$store.state.Languages.all;
    },
    locale() {
        return this.$root.$i18n.locale;
      },
  },
};
</script>