import { BaseModel } from "./base.model";

export class Speaker extends BaseModel {
  constructor(
    public name?: string,
    public description?: string,
    public avatar?: string,
    public url?: string
  ) {
    super();
  }
}