<template>
  <div>
    <h2 class="mt-3">{{ $t("livestream") }}</h2>
    <b-tabs class="mt-3">
      <b-tab v-for="(language, index) in languages" :key="index">
        <template #title>
          <country-flag :country="language" size="normal" />
        </template>
        <div class="mt-3 mb-3">
          <b-button
            class="mb-3"
            v-if="livestream.type[language] == 'whereby'"
            @click="toggleModal()"
            >Create new room</b-button
          >
          <WherebyCreateRoom
            v-if="livestream.type[language] == 'whereby'"
            v-model="showModal"
            :whereby="livestream"
            :language="language"
            :isLoading="isLoading"
            v-on:childToParent="fillRoomUrl(language, ...arguments)"
          />
          <!-- Choose platform -->
          <b-form mt="25" inline>
            <b-form-group class="mb-3 mr-sm-2 mb-sm-0" label="Platform">
              <b-form-select
                v-model="livestream.type[language]"
                :options="livestreamPlatforms"
              >
              </b-form-select>
            </b-form-group>
            <!-- EMBED CODE -->
            <b-form-group
              v-if="livestream.type[language] !== 'zoom'"
              class="mb-2 mr-sm-2 mb-sm-0"
              label="Livestream url"
            >
              <b-input-group
                :prepend="LivestreamURLPrefix(livestream.type[language])"
              >
                <b-form-input
                  v-model="livestream.code[language]"
                ></b-form-input>
                <!-- <a>Where can I find this?</a> -->
              </b-input-group>
            </b-form-group>
          </b-form>
          <!-- Zoom settings -->
          <div v-if="livestream.type[language] == 'zoom'">
            <b-form inline>
              <b-form-group label="API key">
                <b-form-input v-model="livestream.api_key"></b-form-input>
              </b-form-group>

              <b-form-group class="ml-5" label="API Secret">
                <b-form-input v-model="livestream.api_secret"></b-form-input>
              </b-form-group>
            </b-form>

            <b-form-group label="Password">
              <b-form-input v-model="livestream.password"></b-form-input>
            </b-form-group>

            <b-form-group label="Leave URL">
              <b-form-input v-model="livestream.leaveUrl"></b-form-input>
            </b-form-group>
          </div>
          <!-- Title / Description -->
          <b-form-group label="Inactive text">
            <b-form-input v-model="livestream.inactiveText[language]"></b-form-input>
          </b-form-group>
          <b-form-group label="Title">
            <b-form-input v-model="livestream.title[language]"></b-form-input>
          </b-form-group>
          <b-form-group label="Description">
            <b-form-textarea
              v-model="livestream.description[language]"
            ></b-form-textarea>
          </b-form-group>

          <!-- Whereby settings -->
          <div v-if="livestream.type[language] == 'whereby'">
            <b-form-group label="Host room key">
              <b-form-textarea
                v-model="livestream.roomKey[language]"
              ></b-form-textarea>
            </b-form-group>
            <b-form-group
              v-for="(feature, index) in wherebyToggles"
              :key="index"
              class="mt-3"
            >
              <b-form-checkbox v-model="livestream[feature.value]" switch>{{
                feature.text
              }}</b-form-checkbox>
            </b-form-group>
            <b-form-group label="User url">
              <b-input-group class="mb-3">
                <b-form-input
                  :value="wherebyLinkUser(language)"
                  v-on:focus="$event.target.select()"
                  :ref="'userLink'"
                  readonly
                ></b-form-input>
                <b-input-group-append>
                  <b-button @click="copyUserLink()" variant="info"
                    >Copy</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-form-group label="Moderator url">
              <b-input-group>
                <b-form-input
                  :value="wherebyLinkMod(language)"
                  v-on:focus="$event.target.select()"
                  :ref="'modLink'"
                  readonly
                ></b-form-input>
                <b-input-group-append>
                  <b-button @click="copyModLink()" variant="info"
                    >Copy</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import WherebyCreateRoom from "@/components/admin/whereby/WherebyCreateRoom.vue";

export default {
  props: ["livestream"],
  components: {
    WherebyCreateRoom,
  },
  data() {
    return {
      showModal: false,
      isLoading: false,
      livestreamPlatforms: [
        { value: "vimeoEvent", text: "Vimeo event" },
        { value: "vimeoPlayer", text: "Vimeo player" },
        { value: "youtube", text: "Youtube" },
        { value: "zoom", text: "Zoom" },
        { value: "whereby", text: "WhereBy" },
      ],
      wherebyToggles: [
        { value: "chatToggle", default: true, text: "Chat" },
        {
          value: "videoToggle",
          default: true,
          text: "Video: Participant joins the meeting with camera turned off.",
        },
        {
          value: "audioToggle",
          default: true,
          text: "Audio: 	Participant joins the meeting with microphone turned off.",
        },
        {
          value: "screenshare",
          default: true,
          text: "Screenshare: Show/hide the screenshare button.",
        },
        {
          value: "people",
          default: true,
          text: "People: 	Hide the people button.",
        },
        {
          value: "leaveButton",
          default: true,
          text: "Leave button:	Show/hide the leave button.",
        },
        {
          value: "background",
          default: true,
          text: "Background: Hide the meeting background.",
        },
        {
          value: "logo",
          default: true,
          text: "Logo: 	Hide the logo in the room header.",
        },
        {
          value: "locking",
          default: true,
          text: "Locking: Hide the room lock button.",
        },
        {
          value: "participantCount",
          default: true,
          text: "Participant counter: Hide the participant counter.",
        },
        {
          value: "settingsButton",
          default: true,
          text: "Settings button:	Hide the settings button.",
        },
        {
          value: "moreButton",
          default: true,
          text: "More button:	Hide the more button.",
        },
        {
          value: "breakout",
          default: true,
          text: "Breakout button:	Show/hide the breakout room feature for the meeting host.",
        },
        {
          value: "timer",
          default: true,
          text: "Timer: 	Show/hide the meeting timer.",
        },
        {
          value: "precallReview",
          default: true,
          text: "Pre-call review:	Determines if users see the pre-call review step.",
        },
        {
          value: "personality",
          default: true,
          text: "Personality: 		Can be used to turn off “cheery” UI language that may not fit certain businesses.",
        },
      ],
    };
  },
  methods: {
    LivestreamURLPrefix(type) {
      let url;
      switch (type) {
        case "vimeoEvent":
          url = "https://vimeo.com/";
          break;
        case "vimeoPlayer":
          url = "https://player.vimeo.com/video/";
          break;
        case "youtube":
          url = "https://www.youtube.com/embed/";
          break;
        case "whereby":
        case "zoom":
          url = "";
          break;
        default:
          url = "No livestream platform specified";
      }
      return url;
    },
    wherebyLinkUser(language) {
      let link = `${this.livestream.code[language]}?embed&chat=${
        this.livestream.chatToggle ? "on" : "off"
      }&video=${this.livestream.videoToggle ? "on" : "off"}&audio=${
        this.livestream.audioToggle ? "on" : "off"
      }&screenshare=${this.livestream.screenshare ? "on" : "off"}&people=${
        this.livestream.people ? "on" : "off"
      }&leaveButton=${this.livestream.leaveButton ? "on" : "off"}&logo=${
        this.livestream.logo ? "on" : "off"
      }&background=${this.livestream.background ? "on" : "off"}&locking=${
        this.livestream.locking ? "on" : "off"
      }&participantCount=${
        this.livestream.participantCount ? "on" : "off"
      }&settingsButton=${
        this.livestream.settingsButton ? "on" : "off"
      }&moreButton=${this.livestream.moreButton ? "on" : "off"}&breakout=${
        this.livestream.breakout ? "on" : "off"
      }&timer=${this.livestream.timer ? "on" : "off"}&precallReview=${
        this.livestream.precallReview ? "on" : "off"
      }&personality=${this.livestream.personality ? "on" : "off"}`;
      console.log(link);
      return link;
    },
    wherebyLinkMod(language) {
      let link = this.wherebyLinkUser(language);
      return (link += `&roomKey=${this.livestream.roomKey[language]}`);
    },
    copyModLink() {
      this.$refs.modLink.focus();
      document.execCommand("copy");
    },
    copyUserLink() {
      this.$refs.userLink.focus();
      document.execCommand("copy");
    },
    fillRoomUrl(language, value) {
      this.response = value;
      this.livestream.code[language] = value.roomUrl;
      this.livestream.hostRoomUrl[language] = value.hostRoomUrl;
      console.log(this.livestream.hostRoomUrl);
      this.livestream.roomKey[language] = this.roomKey(
        this.livestream.hostRoomUrl[language]
      );
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    roomKey(hostRoomUrl) {
      console.log(hostRoomUrl);
      return hostRoomUrl.substr(
        hostRoomUrl.indexOf("=") + 1,
        hostRoomUrl.length
      );
    },
  },
  computed: {
    languages() {
      return this.$store.state.Languages.all;
    },
  },
};
</script>
